import React, { useState, useContext } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Background from "../components/backgroundImage"
import Estimator from "../components/PricingCalculator/estimator"

// Data
import { data } from "../data/services"

// Context API data
import { AppContext } from "../store/AppContext"

const Pricing = () => {
  const globalData = useContext(AppContext)
  const [activeTab, setActiveTab] = useState("Website_Development")
  const [appState, setAppState] = useState(
    data.map(section => {
      return {
        id: section.id,
        inputs: section.inputs.map(inp => ({
          id: inp.id,
          currentValue: inp.defaultValue,
        })),
      }
    })
  )

  // prettier-ignore
  const updateSliderValue = (cardId, sectionId, currValue) => {
    setAppState((prev) => {
      const result = [...prev];

      const budgetCard = result.find((card) => card.id === cardId);

      if (budgetCard) {
        const input = budgetCard.inputs.find((inp) => inp.id === sectionId);

        if (input) {
          input.currentValue = Number(currValue);
        }
      }
      if (sectionId === "customWebGraphics" && currValue == 1500) {
        data.forEach((sliderItem) => {
          sliderItem.inputs.forEach((input) => {
            if (input.id === "animatedGraphics") {

              input.isHidden = false;
            }
          });
        });
      } else if (sectionId === "customWebGraphics" && currValue == 0) {
        data.forEach((sliderItem) => {
          sliderItem.inputs.forEach((input) => {
            if (input.id === "animatedGraphics") {
               const totalInputs = budgetCard?.inputs.find(
                 (inp) => inp.id === "animatedGraphics"
              );
              if (totalInputs) {

                totalInputs.currentValue = 0
              }
              input.isHidden = true;
            }
          });
        });
      }

      return result;
    });
  };

  return (
    <Layout>
      <SEO title="Price Estimator" description="Price Estimator" />
      <div className="app">
        <Background
          className={globalData?.state?.customNav ? "pt-2 md:pt-8" : ""}
        >
          <div className="max-w-6xl px-4 pt-8 mx-auto text-white mt-28 md:px-8 xl:px-0">
            <h1 className="pt-8 mb-6 text-3xl font-bold uppercase md:pt-12 md:text-4xl xl:text-5xl siteFont">
              How much will my project cost?
            </h1>
            <p className="text-lg md:text-xl">
              We wanted to make it easy for you to estimate the cost of working
              with us.
            </p>
            <p className="mt-8 text-lg md:text-xl">
              This is the same tool we use internally to provide general
              estimates and is usually quite close to our final quoted price. Of
              course, no one project is the same so there may be exceptions.
            </p>
          </div>
        </Background>
        <Estimator
          data={data}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          appState={appState}
          setAppState
          updateSliderValue={updateSliderValue}
        />
      </div>
    </Layout>
  )
}

export default Pricing
